import React, { useMemo } from "react";
// import { motion } from "framer-motion";
import { Box, Card, Typography, alpha } from "@mui/material";
import { truncateString } from "../../Utils/truncateString";

const ArticleCard = (props) => {
  const { post, index } = props;
  console.log(post);
  const isEven = index % 2 === 0;

  const animationVariants = useMemo(
    () => ({
      hidden: {
        opacity: 0,
        x: isEven ? 200 : -200,
      },
      visible: {
        opacity: 1,
        x: 0,
        transition: {
          type: "spring",
          duration: 2,
          delay: 0.2,
        },
      },
    }),
    [isEven]
  );

  const domain_name = post.domain_name;
  let backgroundColor;
  let textColor;

  switch (domain_name) {
    case "Python":
      backgroundColor = alpha("#9ebdf0", 0.5);
      textColor = "#000000";
      break;
    case "Data Science":
      backgroundColor = alpha("#f09eb4", 0.5);
      textColor = "#000000";
      break;
    case "Algorithms":
      backgroundColor = alpha("#f09eec", 0.5);
      textColor = "#000000";
      break;
    case "Data Engineering":
      backgroundColor = alpha("#9eeef0", 0.5);
      textColor = "#000000";
      break;
    case "Technology":
      backgroundColor = alpha("#9ef0ac", 0.5);
      textColor = "#000000";
      break;
    case "Data Visualization":
      backgroundColor = alpha("#eef09e", 0.5);
      textColor = "#000000";
      break;
    case "NLP":
      backgroundColor = alpha("#f0bc9e", 0.5);
      textColor = "#000000";
      break;
    case "SQL":
      backgroundColor = alpha("#ac9ef0", 0.5);
      textColor = "#000000";
      break;
    default:
      backgroundColor = alpha("#b2b1b5", 0.5);
      textColor = "#FFFFFF";
  }

  return (
    <Card
      sx={{
        m: 2,
        display: "grid",
        gridAutoFlow: "column",
        gap: 2,
        position: "relative",
        borderRadius: "10px",
        overflow: "hidden",
        // backgroundColor: "",
        color: textColor,
        "&:hover": { transform: "scale(1.03)", transition: "0.4s" },
      }}
    >
      <Box
        className="Bg"
        sx={{
          height: "100%",
          width: "150px",
          borderRadius: "20%",
          backgroundColor: backgroundColor,
          position: "absolute",
          left: "-80px",
          display: "flex",
          alignItems: "center",
          transition: "all 0.4s ease",
          ":hover": {
            width: "100vw",
            height: "100%",
            borderRadius: "0",
            backgroundColor: alpha(backgroundColor, 0.3),
            color: "lightblue",
          },
          ":hover .title": {
            opacity: 0,
            transition: "opacity 0s ease",
          },
        }}
      >
        <Typography
          className="title"
          sx={{
            position: "absolute",
            ml: 5,
            p: 1,
            mt: -1,
            textAlign: "center",
            transform: "rotate(90deg)",
            width: "100%",
            fontSize: "15px",
          }}
        >
          {domain_name}
        </Typography>
      </Box>
      <Typography
        component="div"
        variant="body2"
        sx={{
          padding: 1,
          m: 2,
          ml: 10,
          alignContent: "center",
          zIndex: "100",
        }}
      >
        <Typography gutterBottom variant="h5" component="div">
          {post.name}
        </Typography>
        {truncateString(post.description, 300)}...
        <Typography component="div" variant="body1">
          Views: {post.view_count}
        </Typography>
      </Typography>
    </Card>
  );
};

export default ArticleCard;
