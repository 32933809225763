import React from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormGroup,
  TextField,
  Paper,
} from "@mui/material";
import { RichQuizContainer } from "../wrappers/RichQuizContainer";

const QuizQuestion = ({ questionData, onAnswerChange, answers }) => {
  const { id, question, question_type, options } = questionData;

  const handleOptionChange = (event) => {
    const optionId = parseInt(event.target.value);
    const { id: questionId } = questionData;

    if (question_type === "radio") {
      onAnswerChange(questionId, [optionId]);
    } else if (question_type === "checkbox") {
      const selectedAnswers = answers[questionId] || [];
      const updatedAnswers = selectedAnswers.includes(optionId)
        ? selectedAnswers.filter((id) => id !== optionId)
        : [...selectedAnswers, optionId];
      onAnswerChange(questionId, updatedAnswers);
    }
  };

  const renderOptions = () => {
    switch (question_type) {
      case "radio":
        return (
          <RadioGroup
            name={`question_${id}`}
            value={answers[id] || ""}
            onChange={handleOptionChange}
          >
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                value={option.id.toString()}
                control={<Radio />}
                // label={<RichQuizContainer htmlContent={option.text} />}
                label={
                  <RichQuizContainer
                    htmlContent={`<span class="reset_style">${option.text}</span>`}
                  />
                }
                sx={{ my: 1 }}
              />
            ))}
          </RadioGroup>
        );
      case "checkbox":
        return (
          <FormGroup>
            {options.map((option) => (
              <FormControlLabel
                key={option.id}
                control={<Checkbox onChange={handleOptionChange} />}
                value={option.id.toString()}
                checked={(answers[id] || []).includes(option.id)}
                label={<RichQuizContainer htmlContent={option.text} />}
                sx={{ my: 1 }}
              />
            ))}
          </FormGroup>
        );
      default:
        return (
          <TextField
            id={`text_${id}`}
            name={`text_${id}`}
            multiline
            rows={4}
            variant="outlined"
            placeholder="Type your answer here..."
            value={answers[id] || ""}
            onChange={(e) => onAnswerChange(id, e.target.value)}
            sx={{ mt: 2, width: "100%" }}
          />
        );
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        p: 4,
        maxWidth: 800,
        width: "100%",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
      }}
    >
      <RichQuizContainer htmlContent={question} />
      <FormControl sx={{ ml: 3 }} component="fieldset">
        {renderOptions()}
      </FormControl>
    </Paper>
  );
};

export { QuizQuestion };
