import React, { useState, useEffect, useCallback } from "react";
import Editor from "@monaco-editor/react";
import "./MonacoCodeEditor.scss";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import DOMPurify from "dompurify";

const languages = [
  { label: "Python", value: "python" },
  // { label: "JavaScript", value: "javascript" },
];

const themes = [
  { label: "Light", value: "light" },
  { label: "Dark", value: "vs-dark" },
];

const EditorHeader = ({
  language,
  handleLanguageChange,
  theme,
  handleThemeChange,
}) => {
  return (
    <Box sx={{ paddingLeft: 4, width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl fullWidth>
            <Select
              value={language}
              onChange={handleLanguageChange}
              displayEmpty
              variant="outlined"
              // disabled
              readOnly
            >
              {languages.map((lang) => (
                <MenuItem key={lang.value} value={lang.value}>
                  {lang.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <FormControl fullWidth>
            <Select
              value={theme}
              onChange={handleThemeChange}
              displayEmpty
              variant="outlined"
            >
              {themes.map((th) => (
                <MenuItem key={th.value} value={th.value}>
                  {th.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

const MonacoCodeEditor = ({ problemId }) => {
  const theme = useTheme();
  const [code, setCode] = useState("");
  const [language, setLanguage] = useState(languages[0].value);
  const [editorTheme, setEditorTheme] = useState(themes[1].value);
  const [position, setPosition] = useState({ line: 1, column: 1 });
  const [output, setOutput] = useState("");
  const [error, setError] = useState("");
  const [result, setResult] = useState("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const id = problemId;

  useEffect(() => {
    const savedCode = localStorage.getItem(language);
    if (savedCode) {
      setCode(savedCode);
    } else {
      setCode("");
    }
  }, [language]);

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleThemeChange = (e) => {
    setEditorTheme(e.target.value);
  };

  const handleEditorChange = useCallback(
    (value) => {
      setCode(value);
      localStorage.setItem(language, value);
    },
    [language]
  );

  const handleEditorDidMount = (editor) => {
    editor.onDidChangeCursorPosition((event) => {
      setPosition({
        line: event.position.lineNumber,
        column: event.position.column,
      });
    });
  };

  const handleSubmit = async () => {
    setIsButtonLoading(true);
    try {
      // Sanitize the code before sending it to the API
      const sanitizedCode = DOMPurify.sanitize(code);

      const response = await axios.post("http://104.197.243.224/ide/run", {
        id,
        code: sanitizedCode,
      });

      if (response.status === 200) {
        const { std_output, std_error, result } = response.data;

        setOutput(DOMPurify.sanitize(std_output));
        setError(DOMPurify.sanitize(std_error));
        setResult(DOMPurify.sanitize(result));

        if (std_output) {
          toast.success("Code compiled successfully!");
        } else if (std_error) {
          toast.error("Code compiled with errors!");
        } else {
          toast.error("No output received from the server.");
        }
      } else {
        toast.error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      console.error("Error submitting code:", error);

      if (error.response) {
        const { status, data } = error.response;
        console.error(`Server responded with status ${status}:`, data);

        // Extract std_error if available
        const std_error = data?.std_error
          ? DOMPurify.sanitize(data.std_error)
          : "";

        switch (status) {
          case 400:
            toast.error("Bad request. Please check your input.");
            break;
          case 401:
            toast.error("Unauthorized. Please log in and try again.");
            break;
          case 403:
            toast.error(
              "Forbidden. You do not have permission to perform this action."
            );
            break;
          case 404:
            toast.error(
              "Not found. The requested resource could not be found."
            );
            break;
          case 500:
            toast.error("Internal server error. Please try again later.");
            break;
          default:
            toast.error(`Unexpected error occurred: ${status}`);
        }
        if (std_error) {
          setError(std_error);
          toast.error(std_error);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error(
          "No response from server. Please check your network connection."
        );
      } else {
        console.error("Error setting up request:", error.message);
        toast.error("Error setting up request. Please try again.");
      }
    } finally {
      setIsButtonLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 2,
        }}
      >
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              color: "theme.palette.secondary.main",
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
              fontSize: "1.2rem",
              textAlign: "center",
              marginTop: "20px",
              marginBottom: "10px",
            }}
          >
            {/* {problem.title} */}
          </Typography>
        </Box>
        <EditorHeader
          language={language}
          handleLanguageChange={handleLanguageChange}
          theme={editorTheme}
          handleThemeChange={handleThemeChange}
        />
        <Grid
          container
          spacing={2}
          sx={{ width: "100%", marginTop: 0, padding: 1 }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                position: "relative",
              }}
            >
              <Editor
                className="monaco-code-editor"
                // language={language}
                language="python"
                theme={editorTheme}
                value={code}
                onChange={handleEditorChange}
                onMount={handleEditorDidMount}
                options={{
                  fontSize: 14,
                  minimap: { enabled: false },
                  scrollbar: { vertical: "auto", horizontal: "auto" },
                  overviewRulerLanes: 0,
                  hideCursorInOverviewRuler: true,
                  lineDecorationsWidth: 0,
                  lineNumbersMinChars: 3,
                  renderLineHighlight: "none",
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  position: "absolute",
                  bottom: 0,
                  right: 10,
                  backgroundColor: "#fafafa",
                  padding: "2px 8px",
                  borderRadius: 1,
                  boxShadow: 1,
                }}
              >
                Line: {position.line}, Col: {position.column}
              </Typography>
            </Box>
          </Grid>

          {(output || error) && (
            <Grid item xs={12} sx={{ paddingTop: "0px" }}>
              <Box
                sx={{
                  paddingTop: 0,
                  padding: 2,
                  border: "1px solid #ddd",
                  borderRadius: 1,
                  minHeight: "20vh",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {output ? <>Output: </> : error ? <>Error: </> : null}
                </Typography>

                <Box
                  sx={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    padding: 2,
                  }}
                >
                  {output && <React.Fragment>{output}</React.Fragment>}
                  {error && <React.Fragment>{error}</React.Fragment>}
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
        <Typography
          variant="body2"
          sx={{
            color: "theme.palette.error.main",
            fontFamily: "Arial, sans-serif",
            fontWeight: "bold",
            fontSize: "1.2rem",
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "10px",
          }}
        >
          {result && <React.Fragment>Result: {result}</React.Fragment>}
        </Typography>
        <Box
          sx={{
            width: "100%",
            padding: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{
              display: "block",
              backgroundColor: "primary.main",
              borderRadius: 20,
              marginY: 3,
              marginX: "auto",
              width: "25%",
              "&:hover": {
                backgroundColor: "inherit",
                color: "primary.main",
                outline: `1px solid ${theme.palette.primary.main}`,
              },
            }}
            disabled={isButtonLoading}
          >
            {isButtonLoading ? (
              <CircularProgress size={24} sx={{ color: "primary.main" }} />
            ) : (
              "Submit Code"
            )}
          </Button>
        </Box>
        <ToastContainer />
      </Box>
    </ThemeProvider>
  );
};

export default MonacoCodeEditor;
