import { getAllPosts } from "../../../../ApiServices/auth.service";
import { PostActionTypes } from "../constants/posts-constants";

export const fetchPostsRequest = () => ({
  type: PostActionTypes.POSTS_REQUEST,
});

export const fetchPostsSuccess = (posts) => ({
  type: PostActionTypes.POSTS_SUCCESS,
  payload: posts,
});

export const fetchPostsFailure = (error) => ({
  type: PostActionTypes.POSTS_FAILURE,
  payload: error,
});

export const fetchAllPosts = () => async (dispatch) => {
  dispatch(fetchPostsRequest());
  try {
    const response = await getAllPosts();
    dispatch(fetchPostsSuccess(response.data));
  } catch (error) {
    dispatch(fetchPostsFailure(error.message));
  }
};
