import { getLanguages } from "../../../../ApiServices/auth.service";
import { QuizActionTypes } from "../constants/quizzes-constants";

export const fetchQuizzesRequest = () => ({
  type: QuizActionTypes.QUIZ_REQUEST,
});

export const fetchQuizzesSuccess = (quizzes) => ({
  type: QuizActionTypes.QUIZ_SUCCESS,
  payload: quizzes,
});

export const fetchQuizzesFailure = (error) => ({
  type: QuizActionTypes.QUIZ_FAILURE,
  payload: error,
});

export const fetchAllQuizzes = () => async (dispatch) => {
  dispatch(fetchQuizzesRequest());
  try {
    const response = await getLanguages();
    dispatch(fetchQuizzesSuccess(response.data));
  } catch (error) {
    dispatch(fetchQuizzesFailure(error.message));
  }
};
