import axios from "axios";

// const baseURL = "http://localhost:8000";
const baseURL = "https://theknowhub.com";
const AUTH_HEADER = "Authorization";
const CONTENT_TYPE_HEADER = "Content-Type";
const BEARER = "Bearer ";

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    [CONTENT_TYPE_HEADER]: "application/json",
  },
});

// Function to set Authorization header
const setAuthHeader = (token) => {
  instance.defaults.headers[AUTH_HEADER] = BEARER + token;
};

// Add a request interceptor to include the Authorization header
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers[AUTH_HEADER] = BEARER + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refresh_token");
        const response = await instance.post(
          "/api/auth/refresh-token",
          refreshToken
        );

        if (response.status === 200) {
          const newAccessToken = response.data.access_token;
          localStorage.setItem("access_token", newAccessToken);
          setAuthHeader(newAccessToken);

          originalRequest.headers[AUTH_HEADER] = BEARER + newAccessToken;
          return instance(originalRequest); // Retry the original request with new token
        }
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
