import React, { useEffect, useState } from "react";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Box,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { HeroSection } from "../../Components/common/HeroSection";
import QuizImage from "../../Assets/QuizImage1.svg";
import { Loader } from "../../Components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllQuizzes } from "./redux/actions/quizActions";
import { getQuiz } from "../../ApiServices/auth.service";
import { useTheme } from "@emotion/react";

const tag = "Quick Quizzes:";
const tagline = "Learn, Test and Conquer!";
const imageUrl = QuizImage;

const SelectComponent = ({
  label,
  options,
  onChange,
  value,
  subjects,
  difficulties,
}) => (
  <Grid item xs={12} sm={6} md={4} lg={3}>
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        onChange={(e) => onChange(e.target.value)}
      >
        <MenuItem value="">--Select {label}--</MenuItem>
        {options.map((option, index) => {
          let displayText = option;
          if (label === "Subject") {
            const subject = subjects.find((sub) => sub.subject_name === option);
            if (subject) {
              displayText += ` (${subject.total_quizzes})`;
            }
          } else if (label === "Difficulty") {
            const difficulty = difficulties.find(
              (diff) => diff.level_text === option
            );
            if (difficulty) {
              displayText += ` (${difficulty.no_of_questions})`;
            }
          }
          return (
            <MenuItem key={index} value={option}>
              {displayText}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  </Grid>
);

const QuizSelectionPage = () => {
  const theme = useTheme();
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const {
    quizzes,
    isLoading: isQuizzesLoading,
    error,
  } = useSelector((state) => state.quizzes);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedDifficulty, setSelectedDifficulty] = useState("");

  useEffect(() => {
    if (!quizzes.length) {
      dispatch(fetchAllQuizzes());
    }
  }, [dispatch, quizzes.length]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setSelectedSubject("");
    setSelectedDifficulty("");
  };

  const handleSubjectChange = (subject) => {
    setSelectedSubject(subject);
    setSelectedDifficulty("");
  };

  const handleDifficultyChange = (difficulty) => {
    setSelectedDifficulty(difficulty);
  };

  const startQuiz = async () => {
    try {
      setIsButtonLoading(true);
      if (!selectedLanguage || !selectedSubject || !selectedDifficulty) {
        console.error("Missing selected parameters for quiz start.");
        return;
      }

      const language = quizzes.find(
        (lang) => lang.language_name === selectedLanguage
      );
      const subject = language?.subjects.find(
        (sub) => sub.subject_name === selectedSubject
      );
      const difficulty = subject?.difficulty.find(
        (diff) => diff.level_text === selectedDifficulty
      );

      if (!language || !subject || !difficulty) {
        console.error("Selected quiz details not found.");
        return;
      }

      const startQuizUrl = `/quiz/${selectedLanguage}/${selectedSubject}/${selectedDifficulty}`;

      const response = await getQuiz(
        language.language_id,
        subject.quiz_id,
        difficulty.level_id
      );

      if (response) {
        setIsButtonLoading(false);
        navigate(startQuizUrl, { state: { quiz: response.data } });
      } else {
        console.error("Quiz questions not retrieved.");
      }
    } catch (error) {
      console.error("Error fetching quiz questions:", error.message);
    }
  };

  const language = quizzes.find(
    (lang) => lang.language_name === selectedLanguage
  );
  const subjects = language
    ? language.subjects.map((sub) => sub.subject_name)
    : [];
  const difficultyMap = {
    Beginner: 1,
    Intermediate: 2,
    Advanced: 3,
    Master: 4,
  };

  const difficulties = subjects.find((sub) => sub === selectedSubject)
    ? language.subjects
        .find((sub) => sub.subject_name === selectedSubject)
        .difficulty.sort(
          (a, b) => difficultyMap[a.level_text] - difficultyMap[b.level_text]
        )
        .map((diff) => diff.level_text)
    : [];

  if (error) {
    return <Typography>Error fetching quizzes: {error}</Typography>;
  }

  return (
    <Box>
      <HeroSection tag={tag} tagline={tagline} imageUrl={imageUrl} />
      {isQuizzesLoading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <Typography
            variant="h5"
            gutterBottom
            component="div"
            sx={{
              textAlign: "center",
              my: 3,
              fontFamily: "Noto Sans Variable, sans-serif",
            }}
          >
            Select Language, Subject, and Difficulty to Start
            <Tooltip title="Choose each option to proceed with the quiz">
              <IconButton>
                <InfoIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Typography>
          <Grid container spacing={3} justifyContent="center">
            <SelectComponent
              label="Language"
              options={quizzes.map((lang) => lang.language_name)}
              value={selectedLanguage}
              onChange={handleLanguageChange}
            />
            {selectedLanguage && (
              <SelectComponent
                label="Subject"
                options={subjects}
                value={selectedSubject}
                onChange={handleSubjectChange}
                subjects={language ? language.subjects : []}
              />
            )}
            {selectedSubject && (
              <SelectComponent
                label="Difficulty"
                options={difficulties}
                value={selectedDifficulty}
                onChange={handleDifficultyChange}
                difficulties={
                  language && selectedSubject
                    ? language.subjects.find(
                        (sub) => sub.subject_name === selectedSubject
                      )?.difficulty
                    : []
                }
              />
            )}
            {selectedLanguage && selectedSubject && selectedDifficulty && (
              <Grid item xs={12} textAlign="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={startQuiz}
                  disabled={isButtonLoading}
                  sx={{
                    display: "block",
                    backgroundColor: "primary.main",
                    borderRadius: 20,
                    marginY: 3,
                    marginX: "auto",
                    width: "140px",
                    "&:hover": {
                      backgroundColor: "inherit",
                      color: "primary.main",
                      outline: `1px solid ${theme.palette.primary.main}`,
                    },
                  }}
                >
                  {isButtonLoading ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "primary.main",
                        // backgroundColor: "red",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    "Start Quiz"
                  )}
                </Button>
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
};

export default QuizSelectionPage;
