import React from "react";
import { Typography, Button, Grid, Box, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { useTheme } from "@emotion/react";

const getDifficultyColor = (level) => {
  switch (level) {
    case 1:
      return "#4caf50";
    case 2:
      return "#ff9800";
    case 3:
      return "#f44336";
    default:
      return "#777";
  }
};

const ProblemCard = ({
  problemStatement,
  difficulty,
  onSolveClick,
  isButtonLoading,
}) => {
  const theme = useTheme();
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          border: "1px solid #ddd",
          borderRadius: 10,
          width: 600,
          height: 200,
          padding: 2,
          margin: "0 auto",
          marginBottom: 4,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          background: "linear-gradient(135deg, #ffffff, #f0f2f5)",
        }}
      >
        <Grid item xs={12} sm={8}>
          <Box p={0}>
            <Typography
              variant="h6"
              component="div"
              gutterBottom
              sx={{
                fontWeight: "bold",
                marginBottom: 2,
              }}
            >
              Problem Statement
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: "#555" }}>
              {problemStatement}
            </Typography>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                color: getDifficultyColor(difficulty),
                fontStyle: "italic",
              }}
            >
              {difficulty === 1 ? "Easy" : difficulty === 2 ? "Medium" : "Hard"}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} alignContent={"center"}>
          <Button
            variant="contained"
            color="primary"
            onClick={onSolveClick}
            sx={{
              display: "block",
              backgroundColor: "primary.main",
              marginY: 3,
              marginX: "auto",
              width: "100%",
              borderRadius: 20,
              "&:hover": {
                backgroundColor: "inherit",
                color: "primary.main",
                outline: `1px solid ${theme.palette.primary.main}`,
              },
            }}
            disabled={isButtonLoading}
          >
            {isButtonLoading ? <CircularProgress size={24} /> : "Solve Problem"}
          </Button>
        </Grid>
      </Grid>
    </motion.div>
  );
};

export { ProblemCard };
