import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { QuizQuestion } from "../../Components/quiz/QuizQuestion";
import { verifyQuiz } from "../../ApiServices/auth.service";
import { useTheme } from "@emotion/react";

const QuizPage = () => {
  // Location
  const location = useLocation();
  const quiz = location.state?.quiz;
  const theme = useTheme();

  // Navigate
  const navigate = useNavigate();

  // Params
  const { language, subject, difficulty } = useParams();

  // States
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleAnswerChange = (questionId, answerId) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answerId,
    }));
  };

  const handleNext = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevious = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };

  const handleQuestionSelect = (index) => {
    setCurrentQuestionIndex(index);
  };

  const handleSubmit = async () => {
    const formattedAnswers = [];

    quiz.forEach((questionData) => {
      const { id } = questionData;
      const selectedAnswer = answers[id];

      if (selectedAnswer !== undefined) {
        formattedAnswers.push({
          questionId: id,
          optionId: Array.isArray(selectedAnswer)
            ? selectedAnswer
            : [selectedAnswer],
        });
      }
    });

    try {
      setIsLoading(true);
      const response = await verifyQuiz(formattedAnswers);
      setIsLoading(false);
      if (response) {
        navigate("/quiz-result", { state: { quizResult: response.data } });
      } else {
        console.error("Quiz questions not retrieved.");
      }
    } catch (error) {
      console.error("Error fetching posts:", error.message);
      setIsLoading(false);
    }
  };

  const currentQuestion = quiz[currentQuestionIndex];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Typography variant="h4" sx={{ mb: 3 }}>
        {language} - {subject} ({difficulty})
      </Typography>

      {currentQuestion && (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <QuizQuestion
              questionData={currentQuestion}
              onAnswerChange={handleAnswerChange}
              answers={answers}
            />
          </Grid>
        </Grid>
      )}

      <Box
        sx={{
          mt: 3,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {quiz.map((_, index) => (
          <Button
            key={index}
            variant="outlined"
            onClick={() => handleQuestionSelect(index)}
            sx={{
              minWidth: 36,
              minHeight: 36,
              margin: 0.5,
              borderRadius: "50%",
              backgroundColor:
                currentQuestionIndex === index ? "primary.main" : "inherit",
              color: currentQuestionIndex === index ? "white" : "inherit",
            }}
          >
            {index + 1}
          </Button>
        ))}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Button
          variant="outlined"
          disabled={currentQuestionIndex === 0}
          onClick={handlePrevious}
          sx={{ mr: 2, borderRadius: 20 }}
        >
          Previous
        </Button>
        {currentQuestionIndex < quiz.length - 1 ? (
          <Button
            variant="outlined"
            onClick={handleNext}
            sx={{ mr: 2, borderRadius: 20 }}
          >
            Next
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={isLoading}
          sx={{
            backgroundColor: "primary.main",
            borderRadius: 20,
            marginY: 3,
            marginX: "auto",
            "&:hover": {
              backgroundColor: "inherit",
              color: "primary.main",
              outline: `1px solid ${theme.palette.primary.main}`,
            },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: "primary.color" }} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default QuizPage;
