import React, { useState } from "react";
import { motion } from "framer-motion";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import {
  Box,
  Button,
  CardActions,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { truncateString } from "../../Utils/truncateString";

const FeaturePostCard = ({
  index,
  name,
  date,
  imageUrl,
  slug,
  description,
}) => {
  const isEven = index % 2 === 0;

  const animationVariants = {
    hidden: {
      opacity: 0,
      // x: isEven ? 300 : -300,
      y: isEven ? 50 : -50,
    },
    visible: {
      opacity: 1,
      // x: 0,
      y: 0,
      transition: {
        type: "spring",
        duration: 1,
        delay: 0.3,
      },
    },
  };

  return (
    <motion.div variants={animationVariants} initial="hidden" animate="visible">
      <Card
        sx={{
          width: 250,
          height: 450,
          position: "relative",
          overflow: "hidden",

          "&:hover .content": {
            height: "85%",
          },
          "&:hover .description": {
            opacity: 1,
            maxHeight: "100px",
          },
        }}
      >
        <CardMedia
          component="img"
          height="200"
          // image="https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"
          image={imageUrl}
          alt="Image"
          sx={{
            // border: "1px solid red",
            transition: "transform 0.3s",
          }}
        />
        <Box
          className="content"
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            bgcolor: "background.paper",
            transition: "height 0.3s",
            // height: "250px",
            height: "60%",
            overflow: "hidden",
            backgroundColor: "card.main",
            "&:hover": {
              backgroundColor: "cardhover.main",
            },
          }}
        >
          <CardContent
            sx={{
              borderTop: "1px solid #ddd",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography gutterBottom variant="h6" component="div">
                {name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {date}
              </Typography>
              <Typography
                className="description"
                variant="body2"
                sx={{
                  mt: 2,
                  opacity: 0,
                  transition: "opacity 0.3s, max-height 0.3s",
                  maxHeight: 0,
                  overflow: "hidden",
                }}
              >
                {truncateString(description, 250)}...
              </Typography>
            </Box>
          </CardContent>
          <CardActions
            sx={{
              position: "absolute",
              bottom: 10,
              width: "100%",
              // borderTop: "1px solid #ddd",
              bgcolor: "background.paper",
              backgroundColor: "card.main",
            }}
          >
            <Button size="small" sx={{}}>
              Read More
            </Button>
          </CardActions>
        </Box>
      </Card>
    </motion.div>
  );
};

export { FeaturePostCard };
