import React from "react";
import { motion } from "framer-motion";
import styled from "@emotion/styled";

const RichQuizContainer = ({ htmlContent }) => {
  const StyledContainer = styled(motion.div)`
    /* Base Styles */
    color: #333;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;
    margin: 0 auto;

    /* Quiz Question Styles */
    h2 {
      font-size: 2rem;
      font-weight: bold;
      color: #1a237e;
      margin-bottom: 1.5rem;
      line-height: 1.2;
    }

    .reset_style {
      br {
        // display: none;
      }

      pre {
        background-color: inherit;
        font-family: inherit;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        text-decoration: none;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    code {
      text-wrap: balance;
    }

    pre {
      background-color: #f5f5f5;
      padding: 0 0.5rem;
      border-radius: 8px;
      overflow-x: auto;
      font-family: "Fira Code";
      font-size: 0.9rem;
      line-height: 1.4;
      color: #333;
      // border: 1px solid #ddd;
      // margin-bottom: 2rem;
    }

    /* Quiz Options Styles */
    ul {
      list-style: none;
      padding-left: 0;
    }

    li {
      margin-bottom: 1rem;
      padding: 0.8rem 1.2rem;
      border-radius: 8px;
      background-color: #f5f5f5;
      cursor: pointer;
      transition: background-color 0.3s ease;
    }

    li:hover {
      background-color: #ddd;
    }
  `;

  return (
    <StyledContainer
      // initial={{ opacity: 0, x: 70 }}
      // animate={{ opacity: 1, x: 0 }}
      // exit={{ opacity: 0, x: 20 }}
      // transition={{ duration: 0.5 }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export { RichQuizContainer };
