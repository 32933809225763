import { DomainActionTypes } from "../constants/domains-constants";

const initialState = {
  domains: [],
  isLoading: false,
  error: null,
};
export const domainReducer = (state = initialState, action) => {
  switch (action.type) {
    case DomainActionTypes.DOMAIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case DomainActionTypes.DOMAIN_SUCCESS:
      return {
        ...state,
        domains: action.payload,
        isLoading: false,
      };
    case DomainActionTypes.DOMAIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};
