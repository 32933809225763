import  { getAllProjects } from "../../../../ApiServices/auth.service";
import { ProjectsActionType } from "../constants/projects-constant";

export const fetchProjectsRequest = () => ({
  type: ProjectsActionType.PROJECT_REQUEST,
});

export const fetchProjectsSuccess = (projects) => ({
  type: ProjectsActionType.PROJECT_SUCCESS,
  payload: projects,
});

export const fetchProjectsFailure = (error) => ({
  type: ProjectsActionType.PROJECT_FAILURE,
  payload: error,
});

export const fetchAllProjects = () => async (dispatch) => {
  dispatch(fetchProjectsRequest());
  try {
    const response = await getAllProjects();
    dispatch(fetchProjectsSuccess(response.data));
  } catch (error) {
    dispatch(fetchProjectsFailure(error.message));
  }
};
