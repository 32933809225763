import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  ListItem,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { logout } from "../../ApiServices/auth.service";
import { googleLogout } from "@react-oauth/google";
import LogoutIcon from "@mui/icons-material/Logout";
import LockResetIcon from "@mui/icons-material/LockReset";

const UserAvatarDropdown = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const userData = JSON.parse(
    localStorage.getItem("userData") || '{"FirstName": "USER", "LastName": ""}'
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem) => {
    console.log(`Clicked on ${menuItem}`);
    handleClose();
    navigate(menuItem);
    // Add navigation or other actions here based on menuItem
  };

  const handleLogout = async () => {
    try {
      await googleLogout();
      logout();
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  return (
    <div>
      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleClick}
      > */}
      <Avatar
        alt={`${userData.FirstName} ${userData.LastName}`}
        src="/path/to/avatar.jpg"
        onClick={handleClick}
        sx={{
          ":hover": {
            fontSize: "21px",
            border: `1px solid white`,
          },
        }}
      />
      {/* </Box> */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{ marginTop: 3 }}
      >
        {/* <MenuItem
          sx={{ cursor: "pointer" }}
          onClick={() => handleMenuItemClick("Profile")}
        >
          Analytics
        </MenuItem>
        <MenuItem
          sx={{ cursor: "pointer" }}
          onClick={() => handleMenuItemClick("Profile")}
        >
          Profile
        </MenuItem> */}
        {/* <MenuItem
          sx={{ cursor: "pointer" }}
          onClick={() => handleMenuItemClick("Settings")}
        >
          Settings
        </MenuItem> */}
        <MenuItem
          sx={{
            cursor: "default",
            display: "flex",
            justifyContent: "center",
            "&:hover": { textDecoration: "none" },
          }}
        >
          <Typography variant="h6" color="primary" sx={{ mr: 1 }}>
            {`${userData.FirstName} ${userData.LastName}`}
            <hr
              style={{
                marginTop: 0,
                marginBottom: 0,
                border: 0,
                borderTop: "1px solid black",
                width: "100%",
              }}
            />
          </Typography>
        </MenuItem>
        {/* <MenuItem
          onClick={() => handleMenuItemClick("reset-password")}
          sx={{
            "&:hover": {
              textDecoration: "none",
              color: "primary.main",
            },
          }}
        >
          <LockResetIcon sx={{ mr: 1 }} />
          Reset Password
        </MenuItem> */}
        <MenuItem
          sx={{
            cursor: "pointer",
            color: "primary",
            // display: "flex",
            // justifyContent: "space-between",
            "&:hover": { textDecoration: "none", color: "red" },
          }}
          onClick={handleLogout}
        >
          <LogoutIcon sx={{ mr: 1 }} />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export { UserAvatarDropdown };
