import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Paper,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { GoogleLogin } from "@react-oauth/google";
import BgLines from "../../Assets/BgLines.svg";
import { Google_login, login } from "../../ApiServices/auth.service";
import { useTheme } from "@emotion/react";

const LoginPage = () => {
  const navigate = useNavigate();

  const theme = useTheme();

  const [form, setForm] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [googleLoading, setGoogleLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const response = await login(form);
      localStorage.setItem("access_token", response.data.access_token);
      localStorage.setItem("refresh_token", response.data.refresh_token);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          FirstName: response.data.first_name,
          LastName: response.data.last_name,
        })
      );
      navigate("/");
    } catch (error) {
      setError(
        error.response?.data?.message ||
          "Login failed. Please check your email and password."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    // localStorage.setItem("access_token", credentialResponse.credential);
    // console.log(credentialResponse.credential);
    setGoogleLoading(true);
    try {
      const serverResponse = await Google_login(
        credentialResponse.credential.split(".")[1]
      );
      await new Promise((resolve) => {
        localStorage.setItem("access_token", serverResponse.data.access_token);
        localStorage.setItem(
          "refresh_token",
          serverResponse.data.refresh_token
        );
        resolve();
      });
      localStorage.setItem(
        "userData",
        JSON.stringify({
          FirstName: serverResponse.data.first_name,
          LastName: serverResponse.data.last_name,
        })
      );
      navigate("/");
    } catch (error) {
      setError("Login failed. Please try again.");
    } finally {
      setGoogleLoading(false);
    }
  };

  const handleGoogleFailure = (error) => {
    setError("Google login failed. Please try again.", error);
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${BgLines})`,
        backgroundSize: "cover",
        minHeight: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={3}
        component="main"
        sx={{
          maxWidth: "400px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
        }}
      >
        <Typography component="h1" variant="h5">
          Login
        </Typography>
        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{ width: "100%", padding: 3 }}
        >
          <TextField
            fullWidth
            margin="normal"
            required
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={form.email}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            margin="normal"
            required
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={form.password}
            onChange={handleChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {/* <Grid item xs>
            <Link to="#" variant="body2">
              Forgot password?
            </Link>
          </Grid> */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // onClick={handleClick}
            disabled={loading}
            sx={{
              display: "block",
              backgroundColor: "primary.main",
              marginY: 3,
              marginX: "auto",
              width: "35%",
              "&:hover": {
                backgroundColor: "inherit",
                color: "primary.main",
                outline: `1px solid ${theme.palette.primary.main}`,
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: "primary.main" }} />
            ) : (
              "Login"
            )}
          </Button>
        </Box>
        {/* <Grid
          container
          justifyContent="space-between"
          sx={{ width: "100%", marginBottom: 3 }}
        > */}
        {/* <Grid item xs={6} m="auto"> */}

        <GoogleLogin
          onSuccess={handleGoogleSuccess}
          onError={handleGoogleFailure}
          useOneTap
          prompt="select_account"
          // render={(renderProps) => (
          //   <Button
          //     onClick={renderProps.onClick}
          //     disabled={renderProps.disabled || googleLoading}
          //     fullWidth
          //     sx={{ mt: 2 }}
          //   >
          //     {googleLoading ? (
          //       <CircularProgress size={24} />
          //     ) : (
          //       "Login with Google"
          //     )}
          //   </Button>
          // )}
        />

        {/* </Grid> */}
        {/* <Grid item xs={6} m="auto">
            <Button
              variant="outlined"
              color="primary"
              startIcon={<SiGithub />}
              onClick={handleGithubLogin}
              sx={{ width: "100%" }}
            >
              Sign up with GitHub
            </Button>
          </Grid> */}
        {/* </Grid> */}
        <Grid container sx={{ mt: 4 }}>
          <Grid item xs>
            Not Registered?{" "}
            <Link to="/signup" variant="body2">
              Create Account
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default LoginPage;
