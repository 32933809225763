import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Pagination,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PostsImage from "../../Assets/PostsImage.svg";

import { Loader } from "../../Components/common/Loader";
import { HeroSection } from "../../Components/common/HeroSection";
import ArticleCard from "../../Components/cards/ArticleCard";
import { fetchAllPosts } from "./redux/actions/postsActions";
import { search } from "../../Utils/search";

const PostsListPage = () => {
  const dispatch = useDispatch();
  const { domainName } = useParams();
  const { posts, isLoading: isPostsLoading } = useSelector(
    (state) => state.posts
  );
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    if (!posts.length) {
      dispatch(fetchAllPosts());
    }
  }, [dispatch, posts.length]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
  };

  const postsPerPage = 6;
  const startIndex = (page - 1) * postsPerPage;
  const endIndex = startIndex + postsPerPage;

  const filteredPosts = domainName
    ? posts.filter((post) => post.domain_name === domainName)
    : posts;

  const searchedPosts = search(searchKeyword, filteredPosts);

  const displayedPosts = searchedPosts.slice(startIndex, endIndex);

  return (
    <Box sx={{ width: "100%", margin: "auto" }}>
      <HeroSection
        tag={domainName ? domainName.toUpperCase() : "All Posts"}
        imageUrl={PostsImage}
      />
      <Box sx={{ display: "flex", justifyContent: "center", margin: "2rem 0" }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchKeyword}
          onChange={handleSearchChange}
          sx={{ width: "50%" }}
        />
      </Box>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={8}
          sx={{ padding: "2% 0", margin: "auto", gap: "1rem" }}
        >
          {isPostsLoading ? (
            <Loader />
          ) : displayedPosts.length > 0 ? (
            displayedPosts.map((post, index) => (
              <Paper
                elevation={0}
                key={post.id}
                component={NavLink}
                to={`/post/${post.id}`}
                sx={{ textDecoration: "none", maxWidth: 300, width: "100%" }}
              >
                <ArticleCard post={post} index={index} />
              </Paper>
            ))
          ) : (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", margin: "2rem 0" }}
            >
              Could not find any results for "{searchKeyword}"
            </Typography>
          )}
        </Grid>
        {searchedPosts.length > 0 && (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <Pagination
              count={Math.ceil(searchedPosts.length / postsPerPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
              sx={{ margin: "2%", alignSelf: "center" }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PostsListPage;
