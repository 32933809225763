import React, { useState, useEffect, cloneElement } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useTheme } from "@emotion/react";
import BrandLogo from "../../Assets/tkh.svg";
import { Sidebar } from "./Sidebar";
import { UserAvatarDropdown } from "../common/UserAvatarDropdown";

const learningPages = [
  { name: "Articles", slug: "/posts/articles" },
  // { name: "Courses", slug: "/posts/courses" },
];

const practicePages = [
  { name: "Projects", slug: "/projects" },
  { name: "Quizzes", slug: "/quiz-selection" },
];

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Header(props) {
  const theme = useTheme();
  const location = useLocation();

  const [elevation, setElevation] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openLearning, setOpenLearning] = useState(false);
  const [openPractice, setOpenPractice] = useState(false);

  const isLoggedIn = localStorage.getItem("access_token") ? true : false;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleScroll = () => {
    setElevation(window.scrollY > 0 ? 4 : 0);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <ElevationScroll elevation={elevation} {...props}>
        <AppBar
          position="sticky"
          sx={{
            // backgroundColor: elevation
            //   ? theme.palette.background.paper
            //   : "white",
            backgroundColor: "layout.main",
            px: "1.875rem",
            py: "0.625rem",
            top: 0,
            zIndex: 1000,
            transition: "all 0.1s ease",
          }}
        >
          <Toolbar disableGutters>
            <IconButton
              size="large"
              aria-label="menu"
              onClick={handleDrawerOpen}
              sx={{
                color: theme.palette.primary.main,
                display: { sm: "block", md: "none" },
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box component={NavLink} to={"/"}>
              <img
                // loading="lazy"
                src={BrandLogo}
                alt="brand logo"
                style={{
                  width: "4rem",
                  objectFit: "contain",
                  verticalAlign: "middle",
                }}
              />
            </Box>
            <Typography
              component={NavLink}
              to={"/"}
              variant="h4"
              noWrap
              sx={{
                color: theme.palette.primary.main,
                cursor: "pointer",
                margin: "0 1%",
                textDecoration: "none",
                display: { xs: "none", sm: "flex", md: "flex" },
              }}
            >
              The Knowhub
            </Typography>
            <Box
              sx={{
                display: { xs: "none", sm: "none", md: "flex" },
                // marginX: "auto",
                marginLeft: 5,
                alignItems: "center",
              }}
            >
              <Button
                id="learning-button"
                aria-controls="learning-menu"
                aria-haspopup="true"
                onClick={(event) => setOpenLearning(event.currentTarget)}
                sx={{ color: theme.palette.primary.main, mr: 2 }}
              >
                Learning {openLearning ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="learning-menu"
                anchorEl={openLearning}
                keepMounted
                open={Boolean(openLearning)}
                onClose={() => setOpenLearning(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {learningPages.map((page, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => setOpenLearning(null)}
                    component={NavLink}
                    to={page.slug}
                    sx={{ color: "primary.main" }}
                  >
                    {page.name}
                  </MenuItem>
                ))}
              </Menu>
              <Button
                id="practice-button"
                aria-controls="practice-menu"
                aria-haspopup="true"
                onClick={(event) => setOpenPractice(event.currentTarget)}
                sx={{ color: theme.palette.primary.main, mr: 2 }}
              >
                Practice {openPractice ? <ExpandLess /> : <ExpandMore />}
              </Button>
              <Menu
                id="practice-menu"
                anchorEl={openPractice}
                keepMounted
                open={Boolean(openPractice)}
                onClose={() => setOpenPractice(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                {practicePages.map((page, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => setOpenPractice(null)}
                    component={NavLink}
                    to={page.slug}
                    sx={{ color: "primary.main" }}
                  >
                    {page.name}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box sx={{ flexGrow: 0, position: "absolute", right: 0 }}>
              {isLoggedIn ? (
                <UserAvatarDropdown />
              ) : (
                <React.Fragment>
                  <Button
                    component={NavLink}
                    to={"/login"}
                    variant="contained"
                    sx={{
                      mx: 0.5,
                      color: "#FFF",
                      fontWeight: 700,
                      borderRadius: "15px",
                      textTransform: "none",
                      textDecoration: "none",
                      backgroundColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "inherit",
                        color: "primary.main",
                        outline: `1px solid ${theme.palette.primary.main}`,
                      },
                    }}
                  >
                    Login
                  </Button>
                  <Button
                    component={NavLink}
                    to={"/signup"}
                    variant="contained"
                    sx={{
                      mx: 0.5,
                      color: "#FFF",
                      fontWeight: 700,
                      borderRadius: "15px",
                      textTransform: "none",
                      textDecoration: "none",
                      backgroundColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "inherit",
                        color: "primary.main",
                        outline: `1px solid ${theme.palette.primary.main}`,
                      },
                    }}
                  >
                    Signup
                  </Button>
                </React.Fragment>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Sidebar
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
        learningPages={learningPages}
        practicePages={practicePages}
      />
    </React.Fragment>
  );
}

export { Header };
