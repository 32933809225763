import React from "react";
import { Box, Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Header } from "../../Components/layout/Header";
import { Footer } from "../../Components/layout/Footer";

const LayoutPage = () => {
  return (
    <Box>
      <Header />
      <Container disableGutters={true} maxWidth="xl">
        <Outlet />
      </Container>
      <Footer />
    </Box>
  );
};

export default LayoutPage;
