import { Grid } from "@mui/material";
import React from "react";
import MonacoCodeEditor from "../../Components/code-editor/MonacoCodeEditor";
import { RenderMarkdown } from "../../Components/wrappers/RenderMarkdown";
import { useLocation } from "react-router-dom";

const ProjectPage = () => {
  const location = useLocation();
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          height: "100%",
          overflow: "auto",
          //     border: "1px solid #ddd",
          //     borderRadius: 3,
        }}
      >
        <RenderMarkdown assignment={location.state.body} />
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        sx={{
          height: "100%",
          //   border: "1px solid #ddd",
          //   borderRadius: 3
        }}
      >
        <MonacoCodeEditor problemId={location.state.id} />
      </Grid>
    </Grid>
  );
};

export default ProjectPage;
