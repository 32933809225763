// Function to truncate a string to 300 characters
export function truncateString(inputString, numCharacters = 300) {
  if (typeof inputString !== "string") {
    return "Invalid input. Please provide a valid string.";
  }
  if (typeof numCharacters !== "number" || numCharacters < 0) {
    return "Invalid input. Please provide a valid number of characters.";
  }
  return inputString.slice(0, numCharacters);
}
