import { createTheme, darken, lighten } from "@mui/material/styles";
// import { blue, green } from '@mui/material/colors';

const generateTheme = (primaryMain, secondaryMain) => {
  // Primary light and dark shades based on the main color
  const primaryLightBackground = lighten(primaryMain, 0.85);
  // const primaryDarkBackground = darken(primaryMain, 0.9);
  const primaryLightBackgroundHover = lighten(primaryMain, 0.75);

  // Primary light and dark shades based on the main color
  const secondaryLightBackground = lighten(secondaryMain, 0.9);
  const secondaryDarkBackground = darken(secondaryMain, 0.9);

  // const primaryText = "#fff";
  // const secondaryText = "#000";

  return createTheme({
    palette: {
      primary: {
        main: primaryMain,
      },
      secondary: {
        main: secondaryMain,
      },
      background: {
        // default: primaryLightBackground,
      },
      layout: {
        // main: "#ddedff",
        main: primaryLightBackground,
      },
      card: {
        // main: "#ecf5ff",
        main: primaryLightBackground,
      },
      cardhover: {
        main: primaryLightBackgroundHover,
      },
      // text: {
      //   primary: primaryText,
      //   secondary: secondaryText,
      // },
    },
    // typography: {
    //   // fontFamily: "Roboto, Arial, sans-serif",
    //   h1: {
    //     fontSize: "2.5rem",
    //     fontWeight: 500,
    //     color: "#1a237e",
    //   },
    //   h2: {
    //     fontSize: "2rem",
    //     fontWeight: 500,
    //     color: "#283593",
    //   },
    //   h3: {
    //     fontSize: "1.75rem",
    //     fontWeight: 500,
    //     color: "#303f9f",
    //   },
    //   h4: {
    //     fontSize: "1.5rem",
    //     fontWeight: 500,
    //     color: "#3949ab",
    //   },
    //   h5: {
    //     fontSize: "1.25rem",
    //     fontWeight: 500,
    //     color: "#3f51b5",
    //   },
    //   h6: {
    //     fontSize: "1rem",
    //     fontWeight: 500,
    //     color: "#5c6bc0",
    //   },
    //   body1: {
    //     fontSize: "1rem",
    //     fontWeight: 400,
    //     color: "#333333",
    //   },
    //   body2: {
    //     fontSize: "0.875rem",
    //     fontWeight: 400,
    //     color: "#666666",
    //   },
    //   subtitle1: {
    //     fontSize: "1rem",
    //     fontWeight: 400,
    //     color: "#757575",
    //   },
    //   subtitle2: {
    //     fontSize: "0.875rem",
    //     fontWeight: 400,
    //     color: "#9e9e9e",
    //   },
    //   caption: {
    //     fontSize: "0.75rem",
    //     fontWeight: 400,
    //     color: "#bdbdbd",
    //   },
    //   overline: {
    //     fontSize: "0.75rem",
    //     fontWeight: 400,
    //     color: "#e0e0e0",
    //   },
    // },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
          },
          containedPrimary: {
            backgroundColor: "#161f50",
            color: "#fff",
            "&:hover": {
              backgroundColor: primaryMain,
            },
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: primaryLightBackground,
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            marginBottom: "16px",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            // padding: "16px",
            // borderRadius: "8px",
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: primaryLightBackground,
            width: 240,
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: primaryMain,
          },
          indicator: {
            backgroundColor: "#fff",
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            minWidth: 650,
          },
        },
      },
      MuiSnackbar: {
        styleOverrides: {
          root: {
            backgroundColor: primaryMain,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundColor: secondaryLightBackground,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              // backgroundColor: primaryDarkBackground,
              // color: "#ffffff",
              textDecoration: "underline",
            },
            "&.Mui-selected": {
              backgroundColor: secondaryDarkBackground,
              color: "#ffffff",
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            backgroundColor: primaryLightBackground,
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            "&:hover": {
              // backgroundColor: primaryDarkBackground,
            },
            "&.Mui-selected": {
              backgroundColor: secondaryDarkBackground,
              color: "#1976d2",
            },
          },
        },
      },
    },
  });
};

export default generateTheme;
