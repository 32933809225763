import React from "react";
import DoubleRing from "../../Assets/DoubleRing.svg";

const Loader = () => {
  return (
    <img
      src={DoubleRing}
      alt="Loaeing . . ."
      style={{ width: "100px", margin: "20rem auto", display: "block" }}
    />
  );
};

export { Loader };
